import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import { useQuery } from '@tanstack/react-query';
import { getReports } from '../../api/requests';
import { Table } from '../../components/Table';
import { useCompanyData } from '../../hooks/useCompanyData';
import { Loader } from '../../components/Loader/Loader';

export const Reports = () => {
  const { companyId } = useParams();
  const { companyData, industryData } = useCompanyData(companyId);

  const { data: reportsData, isLoading } = useQuery({
    queryKey: ['reports', companyData?.inn],
    queryFn: async () => {
      const data = await getReports();
      return data?.filter((_) => _.company_inn === companyData?.inn);
    },
    enabled: !!companyData?.inn,
  });

  return (
    <div>
      <Heading className="mb-6">{companyData?.company_name} - Отчёты</Heading>
      <Heading size="S" className="mb-10">
        {companyData?.inn}, {industryData?.industry_name}
      </Heading>
      <Heading size="S">Годовые отчёты компании</Heading>
      <Table
        cols={['Год', 'Ссылка']}
        rows={reportsData?.map((_) => [
          { cell: _.year, sortValue: _.year },
          {
            cell: (
              <Link to={_.link_to_PDF || ''} key={_.id} className="text-link hover:underline">
                {_.link_to_PDF}
              </Link>
            ),
            sortValue: _.link_to_PDF,
          },
        ])}
      />
      {isLoading ? <Loader /> : null}
      {!reportsData?.length && !isLoading ? (
        <Heading className="italic font-normal text-center text-gray" size="XS">
          Данные об отчётах о компании отсутствуют
        </Heading>
      ) : null}
    </div>
  );
};
