import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PageWrapper } from './components/PageWrapper/PageWrapper';
import { Ratings } from './pages/Ratings/Ratings';
import { Rating } from './pages/Rating/Rating';
import { Companies } from './pages/Companies/Companies';
import { Company } from './pages/Company/Company';
import { Reports } from './pages/Reports/Reports';
import { WebSite } from './pages/WebSite/WebSite';
import { News } from './pages/News/News';
import { Login } from './pages/Login/Login';
import { Profile } from './pages/Profile/Profile';

export type ExpandedRoute = RouteObject & {
  name?: string;
  hidden?: boolean;
};

export const routes: ExpandedRoute[] = [
  {
    path: '/',
    hidden: true,
    element: <PageWrapper component={<div />} />,
  },
  {
    path: '/ratings',
    name: 'Рейтинги',
    index: true,
    element: <PageWrapper component={<Ratings />} />,
  },
  {
    path: '/ratings/:ratingId',
    hidden: true,
    element: <PageWrapper component={<Rating />} />,
  },
  {
    path: '/companies',
    name: 'Компании',
    element: <PageWrapper component={<Companies />} />,
  },
  {
    path: '/companies/:companyId',
    hidden: true,
    element: <PageWrapper component={<Company />} />,
  },
  {
    path: '/reports/:companyId',
    hidden: true,
    element: <PageWrapper component={<Reports />} />,
  },
  {
    path: '/news/:companyId',
    hidden: true,
    element: <PageWrapper component={<News />} />,
  },
  {
    path: '/website/:companyId',
    hidden: true,
    element: <PageWrapper component={<WebSite />} />,
  },
  {
    path: '/login',
    hidden: true,
    element: <Login />,
  },
  {
    path: '/profile',
    hidden: true,
    element: <PageWrapper component={<Profile />} />,
  },
];

const router = createBrowserRouter(routes);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>,
);
