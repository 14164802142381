import React from 'react';
import { TableElementProps } from './types';

export const TableCell = ({ children, className = '', style, onClick }: TableElementProps) => {
  return (
    <td style={style} onClick={onClick} className={`py-2 px-3 ${className}`}>
      {children}
    </td>
  );
};
