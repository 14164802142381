import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  getESGRatings,
  getEthicsRatings,
  getGeneralRatings,
  getRatingById,
} from '../../api/requests';
import { Heading } from '../../components/Heading';
import { Table } from '../../components/Table';
import { Loader } from '../../components/Loader/Loader';
import { useLettersData } from '../../hooks/useLettersData';

export const Rating = () => {
  const { ratingId } = useParams();
  const lettersData = useLettersData();

  const { data: rating } = useQuery({
    queryKey: ['rating', ratingId],
    queryFn: () => getRatingById(Number(ratingId)),
    enabled: !!ratingId,
  });

  const { data: allRatings } = useQuery({
    queryKey: ['ratings', ratingId],
    queryFn: async () => {
      const data = await getGeneralRatings();
      return data?.filter((_) => _.rating_name === rating?.rating_name);
    },
    enabled: !!rating,
  });

  const { data: ratingsEsg, isLoading: isLoadingESG } = useQuery({
    queryKey: ['ratingsEsg', ratingId],
    queryFn: async () => {
      if (rating?.esg_rating_tab_id) {
        const data = await getESGRatings();
        return data?.filter((_) => allRatings?.find((__) => __.esg_rating_tab_id === _.id));
      } else {
        return [];
      }
    },
    enabled: !!allRatings && !!rating,
  });

  const { data: ratingsEthics, isLoading: isLoadingEthics } = useQuery({
    queryKey: ['ratingsEthics', ratingId],
    queryFn: async () => {
      if (rating?.ethics_rating_tab_id) {
        const data = await getEthicsRatings();
        return data?.filter((_) => allRatings?.find((__) => __.ethics_rating_tab_id === _.id));
      } else {
        return [];
      }
    },
    enabled: !!allRatings && !!rating,
  });

  return (
    <div>
      <Heading className="mb-6">{rating?.rating_name}</Heading>
      <Heading size="S" className="mb-0">
        {rating?.rating_description}
      </Heading>
      <Heading className="italic font-normal text-gray" size="XS">
        {rating?.comment}
      </Heading>
      {ratingsEsg?.length ? (
        <>
          <Table
            isSortable={true}
            cols={['Компания', 'Score', 'Rank', 'Letter']}
            rows={ratingsEsg?.map(({ year_rating_esg_tab }, i) => [
              {
                cell: (
                  <Link
                    to={`/companies/${year_rating_esg_tab?.company?.id}`}
                    key={`${i}-name`}
                    className="text-main hover:underline"
                  >
                    {year_rating_esg_tab?.company?.company_name}
                  </Link>
                ),
                sortValue: year_rating_esg_tab?.company?.company_name?.toLowerCase(),
              },
              { cell: year_rating_esg_tab?.esg || '-', sortValue: year_rating_esg_tab?.esg },
              {
                cell: year_rating_esg_tab?.esg_rank || '-',
                sortValue: year_rating_esg_tab?.esg_rank,
              },
              {
                cell: (
                  <div key={i} className="bg-green">
                    {year_rating_esg_tab?.esg_letter}
                  </div>
                ),
                sortValue: lettersData?.find(
                  (_) => _.letter_esg === year_rating_esg_tab?.esg_letter,
                )?.rating_esg_index,
                style: {
                  backgroundColor: lettersData?.find(
                    (_) => _.letter_esg === year_rating_esg_tab?.esg_letter,
                  )?.hex,
                },
              },
            ])}
          />
          {isLoadingESG ? <Loader /> : null}
        </>
      ) : null}
      {ratingsEthics?.length ? (
        <>
          <Table
            isSortable={true}
            cols={[
              'Компания',
              'Год',
              'Индекс',
              'Кол-во обзоров (негативных/нейтральных/позитивных)',
            ]}
            rows={ratingsEthics?.map((_, i) => [
              {
                cell: (
                  <Link
                    to={`/companies/${_.year_rating_ethics_tab?.company?.id}`}
                    key={i + '-cmp-name'}
                    className="text-main hover:underline"
                  >
                    {_.year_rating_ethics_tab?.company?.company_name}
                  </Link>
                ),
                sortValue: _.year_rating_ethics_tab?.company?.company_name?.toLowerCase(),
              },
              { cell: _.year, sortValue: _.year },
              {
                cell: _.year_rating_ethics_tab?.index_simple?.toFixed(4),
                sortValue: _.year_rating_ethics_tab?.index_simple,
              },
              {
                cell: (
                  <div key={i} className="text-gray">
                    <span className="text-rating-c">
                      {_.year_rating_ethics_tab?.negative_reviews_count || '-'}
                    </span>
                    /
                    <span className="text-rating-ccc">
                      {_.year_rating_ethics_tab?.neutral_reviews_count || '-'}
                    </span>
                    /
                    <span className="text-rating-aaa">
                      {_.year_rating_ethics_tab?.positive_reviews_count || '-'}
                    </span>
                  </div>
                ),
                sortValue:
                  (_.year_rating_ethics_tab?.negative_reviews_count || 0) +
                  (_.year_rating_ethics_tab?.neutral_reviews_count || 0) +
                  (_.year_rating_ethics_tab?.positive_reviews_count || 0),
              },
            ])}
          />
          {isLoadingEthics ? <Loader /> : null}
        </>
      ) : null}
    </div>
  );
};
