import { logout } from './requests';
import { type HTTPValidationError } from './types';

export const API_BASE_URL = 'https://gitlabethics.ru';
export const LOGIN_API_BASE_URL = 'https://service-audit.ethics.hse.ru';

function instanceOfError(object: any): object is HTTPValidationError {
  return 'detail' in object;
}

export const getRequest = async <T>(url?: string): Promise<T | undefined> => {
  const res = await fetch(`${API_BASE_URL}${url}/`);

  if (instanceOfError(res)) {
    return undefined;
  }

  if (res.status === 401) {
    logout();
  }

  if (res) return await res.json();
};
