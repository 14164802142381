import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCompanies } from '../../api/requests';
import { Table } from '../../components/Table';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';

export const Companies = () => {
  const { data, isLoading } = useQuery({ queryKey: ['companies'], queryFn: getCompanies });

  return (
    <div>
      <Table
        isSortable={true}
        cols={['Название компании', 'ИНН', 'Отрасль', '', '', '']}
        rows={data?.map(({ id, company_name, inn, industry }, i) => [
          {
            cell: (
              <Link to={String(id)} key={i} className="text-main hover:underline">
                {company_name}
              </Link>
            ),
            sortValue: company_name?.toLowerCase(),
          },
          { cell: inn, sortValue: inn },
          { cell: industry?.industry_name, sortValue: industry?.industry_name?.toLowerCase() },
          {
            cell: (
              <Link to={`/reports/${String(id)}`} key={i} className="text-main hover:underline">
                Отчёты
              </Link>
            ),
          },
          {
            cell: (
              <Link to={`/news/${String(id)}`} key={i} className="text-main hover:underline">
                Новости
              </Link>
            ),
          },
          {
            cell: (
              <Link
                to={`/website/${String(id)}`}
                key={i}
                className="text-main hover:underline whitespace-nowrap"
              >
                Веб-сайт
              </Link>
            ),
          },
        ])}
      />
      {isLoading ? <Loader /> : null}
    </div>
  );
};
