import React, { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { Header } from '../Header';
import { Tabs } from '../Tabs';
import { type ExpandedRoute, routes } from '../..';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { useAuthToken } from '../../hooks/useAuthToken';
import { Navigate } from 'react-router-dom';

type PageWrapperProps = {
  component: ReactNode;
};

export const PageWrapper = ({ component }: PageWrapperProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = useAuthToken();

  useEffect(() => {
    if (pathname === '/') {
      navigate('/ratings');
    }
  });

  return token ? (
    <div>
      <Header />
      <Tabs tabs={formatRoutes(routes)} />
      <div className="max-w-screen-xl m-auto">
        {!routes.filter((_) => !_.hidden).find((_) => _.path === pathname) ? (
          <Button variant="no-bg" onClick={() => navigate(-1)}>
            <Heading size="XS" className="flex items-center text-main">
              <MdArrowBack size={24} className="mr-2" />
              Назад
            </Heading>
          </Button>
        ) : null}
        {component}
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

const formatRoutes = (routes: ExpandedRoute[]) => routes.filter((_) => !_.hidden);
