import React, { ReactNode } from 'react';
import { useLocation, Link, RouteObject } from 'react-router-dom';
import { Button } from '../Button';

type TabProps = RouteObject & {
  name?: string;
  index?: boolean;
};

interface TabsProps {
  tabs: TabProps[];
}

export function Tabs({ tabs }: TabsProps) {
  const location = useLocation();

  const currentTab = tabs.find((_) => location.pathname.includes(_.path || ''));

  return (
    <div className="flex w-full items-center justify-center mb-8 mt-4 text-main">
      {tabs.map((tab) => (
        <div
          key={tab.name}
          className={currentTab?.name === tab.name ? 'border-b-2 border-b-main mr-4' : 'mr-4'}
        >
          <Button variant="no-bg">
            <Link
              to={tab.path || ''}
              className={`text-main text-xl pl-6 ${
                !(currentTab?.name === tab.name) ? 'opacity-50' : ''
              }`}
            >
              {tab.name}
            </Link>
          </Button>
        </div>
      ))}
    </div>
  );
}
