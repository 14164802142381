import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUserData, setUserData } from '../../api/requests';
import { AccountData } from '../../api/types';

export function Profile() {
  const userId = localStorage.getItem('userId') || '';

  const [formData, setFormData] = useState<AccountData>({});

  const { data: userData } = useQuery({
    queryKey: ['userProfile', localStorage.getItem('userId')],
    queryFn: async () => {
      const data = await getUserData(localStorage.getItem('userId') || '');
      setFormData(data);

      return data;
    },
  });

  const mutation = useMutation({
    mutationFn: () => setUserData(userId, formData),
  });

  const handleSaveData = () => {
    if (userId) {
      mutation.mutate();
    }
  };

  const handleChangeProfile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="max-w-[900px] mb-4">
      <Heading>Профиль пользователя</Heading>
      <div className="bg-semiDarkGray rounded-xl px-8 py-10">
        <div className="mb-2 flex gap-x-8">
          <Input
            className="w-full"
            title="Имя"
            placeholder="Введите имя"
            name="firstName"
            onChange={handleChangeProfile}
            value={formData?.firstName || ''}
          />
          <Input
            className="w-full"
            title="Фамилия"
            placeholder="Введите фамилию"
            name="lastName"
            onChange={handleChangeProfile}
            value={formData?.lastName || ''}
          />
          <Input
            className="w-full"
            title="Отчество"
            placeholder="Введите отчество"
            name="patronymic"
            onChange={handleChangeProfile}
            value={formData?.patronymic || ''}
          />
        </div>
        <div className="mb-2 flex gap-x-8">
          <Input
            className="w-full"
            title="Почта"
            placeholder="Введите почту"
            name="email"
            onChange={handleChangeProfile}
            value={formData?.email || ''}
          />
          <Input
            className="w-full"
            title="Телефон"
            placeholder="Введите телефон"
            name="phoneNumber"
            onChange={handleChangeProfile}
            value={formData?.phoneNumber || ''}
          />
        </div>
        <Button onClick={handleSaveData}>Сохранить</Button>
      </div>
    </div>
  );
}
