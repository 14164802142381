import React from 'react';
import { Logo } from '../Logo';
import { useQuery } from '@tanstack/react-query';
import { getUserData } from '../../api/requests';
import { buildName } from '../../utils/buildName';
import { Button } from '../Button';
import { ProfileModal } from './ProfileModal';

export function Header() {
  const { data: userData } = useQuery({
    queryKey: ['userData', localStorage.getItem('userId')],
    queryFn: () => getUserData(localStorage.getItem('userId') || ''),
  });

  return (
    <header className="z-10 sticky top-0 flex justify-between gap-12 bg-main py-5 px-24 select-none">
      <div className="flex justify-center items-center gap-4">
        <Logo version="minimized" size={44} />
        <div className="text-white text-xl">Платформа рейтингов ESG</div>
      </div>
      <div className="flex items-center gap-2.5 group">
        <Button className="text-white hover:bg-main pl-0">{buildName(userData)}</Button>
        <ProfileModal />
      </div>
    </header>
  );
}
