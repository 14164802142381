import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getGeneralRatings, getReuters } from '../../api/requests';
import { Table } from '../../components/Table';
import { GeneralRating } from '../../api/types';
import { formatDate } from '../../utils/formatDate';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';

interface CountedGeneralRating extends GeneralRating {
  company_count?: number;
}

export const Ratings = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['ratings'],
    queryFn: async () => {
      const data = await getGeneralRatings();
      return countRatings(data);
    },
  });

  const { data: reuters, isLoading: isReutersLoading } = useQuery({
    queryKey: ['reuters'],
    queryFn: getReuters,
  });

  return (
    <div>
      <Table
        isSortable={true}
        cols={['Название рейтинга', 'Год', 'Рейтер', 'Количество компаний']}
        rows={data?.map((_, i) => [
          {
            cell: (
              <Link to={String(_.id)} key={i} className="text-main hover:underline">
                {_.rating_name}
              </Link>
            ),
            sortValue: _.rating_name?.toLowerCase(),
          },
          { cell: formatDate(_.calculated_on), sortValue: _.calculated_on },
          {
            cell: reuters?.find((__) => __.id === _.reuter_id)?.reuter_name,
            sortValue: reuters?.find((__) => __.id === _.reuter_id)?.reuter_name,
          },
          { cell: _.company_count, sortValue: _.company_count },
        ])}
      />
      {isLoading || isReutersLoading ? <Loader /> : null}
    </div>
  );
};

const countRatings = (ratings?: GeneralRating[]): CountedGeneralRating[] => {
  const countedRatings: CountedGeneralRating[] = [];
  for (const rating of ratings || []) {
    const index = countedRatings.findIndex(
      (_) =>
        _.rating_name === rating.rating_name && _.rating_description === rating.rating_description,
    );
    if (index > -1) {
      countedRatings[index].company_count!++;
    } else {
      countedRatings.push({ ...rating, company_count: 1 });
    }
  }

  return countedRatings;
};
