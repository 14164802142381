import { type AccountData } from '../api/types';

interface BuildNameProps {
  firstName?: AccountData['firstName'];
  lastName?: AccountData['lastName'];
  patronymic?: AccountData['patronymic'];
}

export function buildName(accountData: BuildNameProps) {
  let nameString = accountData?.lastName;
  if (accountData?.firstName) nameString += ` ${accountData?.firstName[0]}.`;
  if (accountData?.patronymic) nameString += ` ${accountData?.patronymic[0]}.`;

  return nameString;
}
