import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getESGRatings, getEthicsRatings, getReuters } from '../../api/requests';
import { useQuery } from '@tanstack/react-query';
import { Heading } from '../../components/Heading';
import { Table } from '../../components/Table';
import { useCompanyData } from '../../hooks/useCompanyData';
import { Loader } from '../../components/Loader/Loader';
import { LetterData, RatingESG, RatingEthics, Reuter } from '../../api/types';
import { type TableProps, type RowCellData } from '../../components/Table/Table';
import { Chart, type ChartOptions } from 'react-charts';
import { useLettersData } from '../../hooks/useLettersData';

export const Company = () => {
  const { companyId } = useParams();
  const { companyData, industryData } = useCompanyData(companyId);
  const lettersData = useLettersData();

  const { data: reuters, isLoading: isReutersLoading } = useQuery({
    queryKey: ['reuters'],
    queryFn: getReuters,
  });

  const { data: ratingsEsg, isLoading: isESGLoading } = useQuery({
    queryKey: ['ratingsEsg', companyData?.inn],
    queryFn: async () => {
      const data = await getESGRatings();
      return data
        ?.filter((_) => _.year_rating_esg_tab?.company?.inn === companyData?.inn)
        ?.sort((a, b) => (a?.year || 0) - (b?.year || 0));
    },
    enabled: !!companyData?.inn,
  });

  const { data: ratingsEthics, isLoading: isEthicsLoading } = useQuery({
    queryKey: ['ratingsEthics', companyData?.inn],
    queryFn: async () => {
      const data = await getEthicsRatings();
      return data?.filter((_) => _.year_rating_ethics_tab?.company?.inn === companyData?.inn);
    },
    enabled: !!companyData?.inn,
  });

  const tableData = useMemo(
    () => buildTableData(ratingsEsg, reuters, lettersData),
    [ratingsEsg, reuters, lettersData],
  );

  const chartDataESG: ChartOptions<RatingESG> = useMemo(
    () => ({
      data:
        reuters?.map((_) => ({
          label: _.reuter_name || '',
          data: ratingsEsg?.filter((__) => __.reuter_id === _.id) || [],
        })) || [],
      primaryAxis: {
        getValue: (data) => data.year || 0,
        scaleType: 'band',
      },
      secondaryAxes: [
        {
          getValue: (data) =>
            lettersData?.find((_) => _.letter_esg === data.year_rating_esg_tab?.esg_letter)
              ?.rating_esg_index,
          formatters: {
            scale: (data: number) =>
              lettersData?.find((_) => _.rating_esg_index === data)?.letter_esg || '',
            tooltip: (data: number) => (
              <div>{lettersData?.find((_) => _.rating_esg_index === data)?.letter_esg || ''}</div>
            ),
          },
          min: 0,
        },
      ],
    }),
    [reuters, ratingsEsg, lettersData],
  );

  const chartDataEthics: ChartOptions<RatingEthics> = useMemo(
    () => ({
      data:
        ratingsEthics?.map((_) => ({
          label: String(_.year_rating_ethics_tab?.index_simple?.toFixed(4) || 0),
          data: ratingsEthics
            .sort((a, b) => (a?.id || 0) - (b?.id || 0))
            .map((_, i) => ({ ..._, id: i + 1 })),
        })) || [],
      primaryAxis: {
        getValue: (data) => data.id,
        scaleType: 'linear',
        formatters: {
          scale: () => '',
          tooltip: () => '',
        },
      },
      secondaryAxes: [
        {
          getValue: (data) => Number(data.year_rating_ethics_tab?.index_simple?.toFixed(4) || 0),
        },
      ],
    }),
    [ratingsEthics],
  );

  return (
    <div>
      <Heading className="mb-6">{companyData?.company_name}</Heading>
      <Heading size="S" className="mb-10">
        {companyData?.inn}, {industryData?.industry_name}
      </Heading>
      <div className="mb-10">
        <Heading size="S">Рейтинги ESG</Heading>
        <Table isSortable={true} cols={tableData?.cols} rows={tableData?.rows} />
        {isESGLoading || isReutersLoading ? <Loader /> : null}
        {!ratingsEsg?.length && !isESGLoading ? (
          <Heading className="italic font-normal text-center text-gray" size="XS">
            Данные рейтинга ESG отсутствуют
          </Heading>
        ) : null}
        {chartDataESG?.data?.length && ratingsEsg?.length ? (
          <div className="h-[200px] mt-10">
            <Chart options={chartDataESG} />
          </div>
        ) : null}
      </div>
      <div className="mb-10">
        <Heading size="S">Индекс этичности</Heading>
        <Table
          isSortable={true}
          cols={['Год', 'Индекс', 'Кол-во обзоров (негативных/нейтральных/позитивных)']}
          rows={ratingsEthics?.map((_, i) => [
            { cell: _.year, sortValue: _.year },
            {
              cell: _.year_rating_ethics_tab?.index_simple?.toFixed(4),
              sortValue: _.year_rating_ethics_tab?.index_simple,
            },
            {
              cell: (
                <div key={i}>
                  <span className="text-rating-c">
                    {_.year_rating_ethics_tab?.negative_reviews_count || '-'}
                  </span>
                  /
                  <span className="text-rating-ccc">
                    {_.year_rating_ethics_tab?.neutral_reviews_count || '-'}
                  </span>
                  /
                  <span className="text-rating-aaa">
                    {_.year_rating_ethics_tab?.positive_reviews_count || '-'}
                  </span>
                </div>
              ),
              sortValue:
                (_.year_rating_ethics_tab?.negative_reviews_count || 0) +
                (_.year_rating_ethics_tab?.neutral_reviews_count || 0) +
                (_.year_rating_ethics_tab?.positive_reviews_count || 0),
            },
          ])}
        />
        {isEthicsLoading ? <Loader /> : null}
        {!ratingsEthics?.length && !isEthicsLoading ? (
          <Heading className="italic font-normal text-center text-gray" size="XS">
            Данные индекса этичности отсутствуют
          </Heading>
        ) : null}
        {chartDataEthics?.data?.length && ratingsEthics?.length ? (
          <div className="h-[200px] mt-10">
            <Chart options={chartDataEthics} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

function buildTableData(ratingsEsg?: RatingESG[], reuters?: Reuter[], lettersData?: LetterData[]) {
  const yearsSet = new Set<number>();
  const cols: string[] = ['Год'];
  const rows: TableProps['rows'] = [];

  ratingsEsg?.forEach(({ year }) => {
    if (year) {
      yearsSet.add(year);
    }
  });

  reuters?.forEach((reuter) => {
    cols.push(reuter.reuter_name || '');
  });

  const years = Array.from(yearsSet).sort();

  years.forEach((year) => {
    const row: RowCellData[] = [{ cell: year, sortValue: year }];
    reuters?.forEach(({ id }) => {
      const ratings = ratingsEsg?.filter((r) => r.year === year && r.reuter_id === id);
      const maxLetterRating = ratings?.reduce(
        (prev, curr) =>
          (lettersData?.find((_) => _.letter_esg === prev.year_rating_esg_tab?.esg_letter)
            ?.rating_esg_index || 0) >
          (lettersData?.find((_) => _.letter_esg === curr.year_rating_esg_tab?.esg_letter)
            ?.rating_esg_index || 0)
            ? prev
            : curr,
        ratings[0],
      );

      const letter = maxLetterRating?.year_rating_esg_tab?.esg_letter || '';
      const letterData = lettersData?.find((_) => _.letter_esg === letter);

      row.push({
        cell: letter,
        sortValue: letterData?.rating_esg_index,
        style: { backgroundColor: letterData?.hex },
      });
    });
    rows.push(row);
  });

  return { cols, rows };
}
