import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface LogoProps {
  version?: 'light' | 'dark' | 'dark-outline' | 'minimized' | 'transparent';
  size?: number;
  className?: string;
}

export function Logo({ version = 'dark-outline', size = 140, className = '' }: LogoProps) {
  const fileName = `${version}-logo.svg`;

  return (
    <Link to='/'>
      <img
        className={className}
        alt={fileName}
        src={`${window.location.origin}/logos/${fileName}`}
        width={size}
        height={size}
      />
    </Link>
  );
}
