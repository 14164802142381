import { useQuery } from '@tanstack/react-query';
import { getCompanyById, getIndustryById } from '../api/requests';
import { type Company, type Industry } from '../api/types';

export const useCompanyData = (
  companyId?: string,
): { companyData?: Company; industryData?: Industry } => {
  const { data: companyData } = useQuery({
    queryKey: ['company', companyId],
    queryFn: () => getCompanyById(Number(companyId)),
  });

  const { data: industryData } = useQuery({
    queryKey: ['industry', companyData?.industry_id],
    queryFn: () => getIndustryById(Number(companyData?.industry_id)),
    enabled: !!companyData?.industry_id,
  });

  return { companyData, industryData };
};
