import React from 'react';

type HeadingProps = JSX.IntrinsicElements['h1'] & {
  size?: 'XS' | 'S' | 'M' | 'L';
  transparent?: boolean;
};

export function Heading({ size = 'M', children, className = '', transparent }: HeadingProps) {
  return (
    <div
      className={`font-semibold ${getHeadingClassName(size)} ${
        transparent ? 'opacity-40' : ''
      } ${className}`}
    >
      {children}
    </div>
  );
}

const getHeadingClassName = (size: HeadingProps['size']) => {
  switch (size) {
    case 'XS':
      return 'mb-2 text-xl';
    case 'S':
      return 'mb-5 text-2xl';
    case 'M':
      return 'mb-14 text-4xl';
    case 'L':
      return 'mb-20 text-6xl';
  }
};
